import React from 'react';
import Logo from './images/hasini-logo.png'

function App() {
  return (
    <div className="wrapper">
      <div className="App">
        <img src={Logo} alt="Hasini prestigious sapphire jewellery" width="598px" height="386px" className="logo" />
        <span>Coming Soon</span>
        <p><a href="https://www.instagram.com/hasini.co/" target="_blank" rel="noopener noreferrer">Visit us on Instagram</a></p>
      </div>
    </div>
  );
}

export default App;
